import React, { Component } from 'react';
import './Home.css';
import canonFire from '../asset/CanonFire.png';
import tank from '../asset/tank.png';
import round from '../asset/canonRound.png';
import apache from '../asset/apache.png';

 const element = (
    <div className="container-fluid">
         <div className="row">

             <div className="col-md-1">
                 <img className='copterMove1' src={apache} alt='apache1'  />
                 <img className='copterMove2' src={apache} alt='apache2'  />
                 <img className='copterMove3' src={apache} alt='apache3'  />
             </div>
             <div className="col-md-4">
                 <table className='tankMove'>
                     <thead></thead>
                     <tbody>
                         <tr>
                             <td>
                                 <img className='rockTank d-block d-lg-none' src={tank} alt='tank' style={{ height: '130px' }}  />
                                 <img className='rockTank d-none d-lg-block' src={tank} alt='tank' style={{ height: '270px' }} />
                             </td>
                             <td>
                                 <img className='fireRound' src={canonFire} alt='flash' style={{ opacity: '0.0' }} />
                             </td>
                             <td>
                                 <img className='canonRound' src={round} alt='round' style={{  opacity: '0.0' }} />
                             </td>
                         </tr>
                     </tbody>
                     <tfoot></tfoot>
                 </table>
            </div>
            <div className="col-md-7 align-middle" style={{ textAlign: 'center' }}>
                <br />
                <table width='100%'>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <td height='70'>
                                <div className='titleBurst'>National Weapons Association</div>
                            </td>
                        </tr>
                        <tr>
                             <td>
                                 <div className='rockTopText'>Our Mission is Protecting America's Second Amendment using Common Sense</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                 <br />
                                 <h2 className="orgPurpose rockText" style={{ transform: 'rotateZ(0deg)'}}>Best Education and Safety Training</h2>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <h2>Starts by Becoming a Member of the USA Military</h2>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot></tfoot>
                </table>
            </div> 
        </div>
        <hr />
        <a href='https://www.marines.com/' target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'underline' }}><h5>United States of America Marine Corp - Learn more today</h5></a>
        <div className="row serviceBlock">
            <div className="col-md-8 marineBackgroundImage" >
            </div>
            <div className="col-md-4 marineWeaponBackgroundImage">
            </div>
        </div>
        <hr />
        <a href='https://www.airforce.com/' target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'underline' }}><h5>United States of America Air Force - Learn more today</h5></a>
        <div className="row serviceBlock">
            <div className="col-md-4 airforceWeaponBackgroundImage">
            </div>
            <div className="col-md-8 airforceBackgroundImage">
            </div>
        </div>
        <hr />
        <a href='https://www.goarmy.com/' target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'underline' }}><h5>United States of America Army - Learn more today</h5></a >
        <div className="row serviceBlock">
            <div className="col-md-8 armyBackgroundImage">
            </div>
            <div className="col-md-4 armyWeaponBackgroundImage">
            </div>
        </div>
        <hr />
        <a href='https://www.navy.com/' target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'underline' }}><h5>United States of America Navy - Learn more today</h5></a >
        <div className="row serviceBlock">
            <div className="col-md-4 navyWeaponBackgroundImage">
            </div>
            <div className="col-md-8 navyBackgroundImage">
            </div>
        </div> 
    </div>
    );

export class Home extends Component { 

    render() {
        return (
        element
        );
    }
}

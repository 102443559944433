import React, { Component } from 'react';
import { Address } from './Address'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'


import './Membership.css';

//const phoneRegEx = new RegExp( /^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-/\s.]?[0-9]{4}$/ );
const amountRegEx = new RegExp(/[0-9]+/);

export class Membership extends Component {

    constructor(props) {
        super(props);
        this.state = {
            countries: [], amountEntered: '', joinPayment: 0, email: '', phone: '', billingAddressSame: true, allowReoccuring: false, paymentRequired: false,  
            shippingFirstName: '', shippingLastName: '', shippingAddress1: '', shippingAddress2: '', shippingCity: '', shippingState: '', shippingZip: '', shippingCountry: 'United States',
            billingFirstName: '', billingLastName: '', billingAddress1: '', billingAddress2: '', billingCity: '', billingState: '', billingZip: '', billingCountry: 'United States',
            creditCardNumber: '', expirationDate: '', ccv: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getCountries();
    }

    handleSubmit = event => {
        event.preventDefault(); 
        return false;
    };

    join(event) {
        alert('join');
    }

    async getCountries() {
        var baseURL = document.getElementsByTagName('base')[0].href; 

        const response = await fetch(baseURL + 'country');
        const data = await response.json();
        this.setState({ countries: data, loading: false });
    }

    onContinue(bar) {
        if ((bar == 'term') && (this.state.joinPayment > 0)) {
            document.getElementById('membershipTerm').style.display = "block";
            document.getElementById('membershipTermBar').style.backgroundColor = "darkred";

            document.getElementById('yourInformation').style.display = "none";
            document.getElementById('yourInformationBar').style.backgroundColor = "gray";
            document.getElementById('paymentInformation').style.display = "none";
            document.getElementById('paymentBar').style.backgroundColor = "gray";
        }
        else if ((bar == 'info') && (this.state.joinPayment > 0)) {
            document.getElementById('yourInformation').style.display = "block";
            document.getElementById('yourInformationBar').style.backgroundColor = "darkred";

            document.getElementById('membershipTerm').style.display = "none";
            document.getElementById('membershipTermBar').style.backgroundColor = "gray";
            document.getElementById('paymentInformation').style.display = "none";
            document.getElementById('paymentBar').style.backgroundColor = "gray";
        }
        else if (bar == 'payment') {
            if (this.state.billingAddressSame)
                this.setBillingAddress();

            this.state.paymentRequired = false;

            if (document.getElementById("joinForm").checkValidity()) {
                document.getElementById('paymentInformation').style.display = "block";
                document.getElementById('paymentBar').style.backgroundColor = "darkred";

                document.getElementById('membershipTerm').style.display = "none";
                document.getElementById('membershipTermBar').style.backgroundColor = "gray";
                document.getElementById('yourInformation').style.display = "none";
                document.getElementById('yourInformationBar').style.backgroundColor = "gray";

                this.state.paymentRequired = true;
            }
        }
    }

    setBillingAddress() {
        document.getElementById('billingFirstName').value = this.state.shippingFirstName;
        document.getElementById('billingLastName').value = this.state.shippingLastName;
        document.getElementById('billingAddress1').value = this.state.shippingAddress1;
        document.getElementById('billingAddress2').value = this.state.shippingAddress2;
        document.getElementById('billingCity').value = this.state.shippingCity;
        document.getElementById('billingState').value = this.state.shippingState;
        document.getElementById('billingZip').value = this.state.shippingZip;
        document.getElementById('billingCountry').value = this.state.shippingCountry;

        this.setState({ ['billingFirstName']: this.state.shippingFirstName });
        this.setState({ ['billingLastName']: this.state.shippingLastName });
        this.setState({ ['billingAddress1']: this.state.shippingAddress1 });
        this.setState({ ['billingAddress2']: this.state.shippingAddress2 });
        this.setState({ ['billingCity']: this.state.shippingCity });
        this.setState({ ['billingState']: this.state.shippingState });
        this.setState({ ['billingZip']: this.state.shippingZip });
        this.setState({ ['billingCountry']: this.state.shippingCountry });
    } 

    joinAmount(amount) {

        if (this.state.joinPayment != 0)
            document.getElementById('join' + this.state.joinPayment + 'Check').style.display = "none";

        this.state.joinPayment = amount;

        document.getElementById('join' + amount + 'Check').style.display = "block";

        document.getElementById('infoButton').style.backgroundColor = 'green';
    }

    handleInputChange(event) {
        switch (event.target.id) {

            case 'creditCardNumber':
            case 'membershipNumber':
            case 'ccv':
            case 'expirationDate':
                this.setState({
                    [event.target.id]: (amountRegEx.exec(event.target.value) != null) ? amountRegEx.exec(event.target.value) : ''
                });
                break;
            default:
                var value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

                if ((event.target.type !== 'checkbox') && ((value == null) || (value.trim() == '')))
                    value = '';

                this.setState({
                    [event.target.id]: value
                });

                if (event.target.id == 'billingAddressSame') {
                    document.getElementById('billingAddressInfo').style.display = value ? 'none' : 'block';

                }

                break;
        }
    }

    render() {
        return (
            <form id='joinForm' class="form-validate" onSubmit={this.handleSubmit}>
                <br />
                <div className='container-fluid' >

                    <div className='row' >
                        <div id='membershipTermBar' className="col-md-12" style={{ backgroundColor: 'darkred', height: '38px', color: 'white', paddingTop: '5px', cursor: 'pointer' }} onClick={() => this.onContinue('term')}>
                            <h4 style={{ width: '400px', margin: 'auto' }}>1. CHOOSE YOUR MEMBERSHIP TERM</h4>
                        </div>
                    </div>

                    <div id="membershipTerm" className='fadeIn' style={{ display: 'block' }}>
                        <br />
                        <div className="row">
                            <div className='offset-md-2 col-md-2'>
                                <div id="join45" onClick={() => this.joinAmount(45)} className='termButton shadow rounded' >
                                    <h5 className='termLength normalValue'>1 YEAR</h5>
                                    <div className='termAmount'>$45</div>
                                    <FontAwesomeIcon id='join45Check' className='fa-3x enlarge' icon={faCheckCircle} style={{ color: 'green', position: 'absolute', bottom: '1px', display: 'none' }} />
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <div id="join75" onClick={() => this.joinAmount(75)} className='termButton  shadow rounded' >
                                    <h5 className='termLength normalValue'>2 YEARS</h5>
                                    <div className='termAmount'>$75</div>
                                    <FontAwesomeIcon id='join75Check' className='fa-3x enlarge' icon={faCheckCircle} style={{ color: 'green', position: 'absolute', bottom: '1px', display: 'none' }} />
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <div id="join100 " onClick={() => this.joinAmount(100)} className='termButton  shadow rounded' >
                                    <h5 className='termLength normalValue'>3 YEARS</h5>
                                    <div className='termAmount'>$100</div>
                                    <FontAwesomeIcon id='join100Check' className='fa-3x enlarge' icon={faCheckCircle} style={{ color: 'green', position: 'absolute', bottom: '1px', display: 'none' }} />
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <div id="join150" onClick={() => this.joinAmount(150)} className='termButton  shadow rounded' >
                                    <div className='bestValue'>BEST VALUE!</div>
                                    <h5 className='termLength'>5 YEARS</h5>
                                    <div className='termAmount'>$150</div>
                                    <FontAwesomeIcon id='join150Check' className='fa-3x enlarge' icon={faCheckCircle} style={{ color: 'green', position: 'absolute', bottom: '1px', display: 'none' }} />
                                </div>
                            </div>
                        </div>
                        <div className='row' >
                            <div className="col-md-12" style={{ textAlign: 'center', width: '100%', fontSize: '14pt' }}>
                                <table style={{ margin: 'auto', marginTop: '40px' }}>
                                    <tr>
                                        <td>
                                            <input id='allowReoccuring' checked={this.state.allowReoccuring} className='form-control' type="checkbox" style={{ width: '30px', height: '30px' }} onChange={this.handleInputChange} ></input>
                                        </td>
                                        <td><label for="allowReoccuring">&nbsp;Allow Reoccurring Billing and Become a Proud Member for Life</label>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12' style={{ textAlign: 'center' }}>
                                <br />
                                <button id='infoButton' style={{ width: '300px', height: '50px', backgroundColor: 'gray', color: 'white', textAlign: 'center', fontSize: '14pt' }} onClick={() => this.onContinue('info')} >
                                    CONTINUE<FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '12px', fontSize: '18pt' }} />
                                </button>
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>

                    <div className='row' >
                        <div id='yourInformationBar' className="col-md-12" style={{ backgroundColor: 'gray', height: '38px', color: 'white', paddingTop: '5px', cursor: 'pointer' }} onClick={() => this.onContinue('info')}  >
                            <h4 style={{ width: '400px', margin: 'auto' }}>2. ENTER YOUR INFORMATION</h4>
                        </div>

                    </div>

                    <div id='yourInformation' className='fadeIn'  style={{ display: 'none' }} >
                        <div className="row">
                            <div className="offset-md-4 col-md-8">
                                <br />
                                <h4>Shipping Address</h4>
                                <br />
                            </div>
                        </div>
                        <div className="row">
                            <div className='offset-md-4 col-md-1'>
                                <label for='email' className='form-control-label required'>
                                    Email Address 
                                </label >  
                            </div>
                            <div className="col-md-3">
                                <input id='email' autofocus class="form-control" type="email" placeholder="example@domain.com" value={this.state.email} onChange={this.handleInputChange} required /> 
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className='offset-md-4 col-md-1'>
                                <label for='phone' className='form-control-label required'>
                                    Phone Number
                                </label >
                            </div>

                            <div className="col-md-3">
                                <input id='phone' class="form-control" type="text" value={this.state.phone} onChange={this.handleInputChange} required />
                            </div>
                        </div>
                        <br />

                        <Address id='shipping' onInputMethod={this.handleInputChange} firstName={this.state.shippingFirstName} lastName={this.state.shippingLastName} address1={this.state.shippingAddress1} address2={this.state.shippingAddress2} city={this.state.shippingCity} state={this.state.shippingState} zip={this.state.shippingZip} country={this.state.shippingCountry} countries={this.state.countries}></Address>

                        <br />
                        <br />

                        <div className='row' >
                            <div className="offset-md-5 col-md-7">
                                <table style={{ fontSize: '14pt' }}>
                                    <tr>
                                        <td>
                                            <input id="billingAddressSame" checked={this.state.billingAddressSame} className='form-control' type="checkbox" style={{ width: '30px', height: '30px' }} onChange={this.handleInputChange} ></input>
                                        </td>
                                        <td><label for="billingAddressSame">&nbsp;Billing and Shipping Address are the Same</label>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <br />

                        <div id='billingAddressInfo' style={{ display: 'none' }}>
                            <div className="row">
                                <div className="offset-md-4 col-md-8">
                                    <br />
                                    <h4>Billing Address</h4>
                                    <br />
                                </div>
                            </div>

                            <Address id='billing' onInputMethod={this.handleInputChange} firstName={this.state.billingFirstName} lastName={this.state.billingLastName} address1={this.state.billingAddress1} address2={this.state.billingAddress2} city={this.state.billingCity} state={this.state.billingState} zip={this.state.billingZip} country={this.state.billingCountry} countries={this.state.countries}></Address>
                        </div>

                        <div className='row'>
                            <div className='col-md-12' style={{ textAlign: 'center' }}>
                                <br />
                                <button  id='infoButton' style={{ width: '300px', height: '50px', backgroundColor: 'green', color: 'white', textAlign: 'center', fontSize: '14pt', cursor: 'pointer' }} onClick={() => this.onContinue('payment')} >
                                    CONTINUE<FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '12px', fontSize: '18pt' }} />
                                </button>
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>

                    <div className='row' >
                        <div id='paymentBar' className="col-md-12" style={{ backgroundColor: 'gray', height: '38px', color: 'white', paddingTop: '5px', cursor: 'pointer' }} onClick={() => this.onContinue('payment')}>
                            <h4 style={{ width: '400px', margin: 'auto' }}>3. ENTER YOUR PAYMENT INFORMATION</h4>
                        </div>
                    </div>

                    <div id='paymentInformation' className='fadeIn'  style={{ display: 'none' }} >

                        <div className="row">
                            <div className="offset-md-4 col-md-8">
                                <br />
                                <h4>Payment Information</h4>
                                <br />
                            </div>
                        </div>
                        <div className="row">
                            <label for='creditCardNumber' className='form-control-label offset-md-4 col-md-1 noPadding required'>
                                Credit Card Number
                                    </label>
                            <div className="col-md-3">
                                <input id='creditCardNumber' maxLength='20' class="form-control" type="text" style={{ maxWidth: '250px' }}  value={this.state.creditCardNumber} onChange={this.handleInputChange} required={this.state.paymentRequired} />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <label for='expirationDate' className='form-control-label offset-md-4 col-md-1 noPadding required'>
                                Expiration Date
                                    </label>
                            <div className="col-md-3">
                                <input id='expirationDate' class="form-control" type="text" maxLength='4' placeholder="MMYY" style={{ width: '80px' }} value={this.state.expirationDate} onChange={this.handleInputChange} required={this.state.paymentRequired} />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <label for='ccv' className='form-control-label offset-md-4 col-md-1 noPadding required'>
                                Security Code
                                    </label>
                            <div className="col-md-3">
                                <input id='ccv' class="form-control" type="text" maxLength='4' style={{ width: '80px' }} value={this.state.ccv} onChange={this.handleInputChange} required={this.state.paymentRequired} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12" style={{ textAlign: "center" }}>
                                <br />
                                <br />
                                <button type='submit' className="joinButton" onClick={() => this.join()} disabled='true'>{this.props.membershipType} Now</button>
                                <br />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

import React, { Component, useLayoutEffect, useState } from 'react'; 
import { NavMenu } from './NavMenu';
import { Footer } from './Footer'
import { Container } from 'reactstrap';

export class Layout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            windowSize: window.innerHeight
        }

        window.addEventListener('resize', this.handleResize);
        window.addEventListener('load', this.handleResize);
    }

    handleResize() {
        var footerHeight = 120;

        document.getElementById('contentID').style.minHeight = window.innerHeight - footerHeight + 'px';
    }

    render() {
        return (
            <div>
                <NavMenu />
                <Container id='contentID' style={{ maxWidth: '1980px', paddingTop: '70px', paddingLeft: '0px', paddingRight: '0px'  }}>
                    {this.props.children}
                </Container>
                <Footer />
            </div>
        );
    }
}

import React, { Component } from 'react';

export class Address extends Component {

    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className='offset-md-4 col-md-1'>
                        <label for={this.props.id + 'FirstName'} className='form-control-label '>
                            First Name
                </label >
                    </div>
                    <div className="col-md-3">
                        <input id={this.props.id + 'FirstName'} class="form-control" type="text" value={this.props.firstName} onChange={this.props.onInputMethod} />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className='offset-md-4 col-md-1'>
                        <label for={this.props.id + 'LastName'} className='form-control-label required'>
                            Last Name
                                </label >
                    </div>

                    <div className="col-md-3">
                        <input id={this.props.id + 'LastName'} class="form-control" type="text" value={this.props.lastName} onChange={this.props.onInputMethod} required />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className='offset-md-4 col-md-1'>
                        <label for={this.props.id + 'Address1'} className='form-control-label required'>
                            Address 1
                                </label >
                    </div>
                    <div className="col-md-3">
                        <input id={ this.props.id + 'Address1'} class="form-control" type="text" value={this.props.address1} onChange={this.props.onInputMethod} required />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className='offset-md-4 col-md-1'>
                        <label for={this.props.id + 'Address2'} className='form-control-label'>
                            Address 2
                                </label >
                    </div>
                    <div className="col-md-3">
                        <input id={this.props.id + 'Address2'} class="form-control" type="text" value={this.props.address2} onChange={this.props.onInputMethod} />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className='offset-md-4 col-md-1'>
                        <label for={this.props.id + 'City'} className='form-control-label required'>
                            City
                                </label >
                    </div>
                    <div className="col-md-3">
                        <input id={this.props.id + 'City'} class="form-control" type="text" value={this.props.city} onChange={this.props.onInputMethod} required />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className='offset-md-4 col-md-1'>
                        <label for={this.props.id + 'State'} className='form-control-label required'>
                            State/Province
                                </label >
                    </div>
                    <div className="col-md-3">
                        <input id={this.props.id + 'State'} class="form-control" type="text" value={this.props.state} onChange={this.props.onInputMethod} required />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className='offset-md-4 col-md-1'>
                        <label for={this.props.id + 'Zip'} className='form-control-label required'>
                            Zip
                        </label >
                    </div>
                    <div className="col-md-3">
                        <input id={this.props.id + 'Zip'} class="form-control" type="text" value={this.props.zip} onChange={this.props.onInputMethod} required />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className='offset-md-4 col-md-1'>
                        <label for={this.props.id + 'Country'} className='form-control-label'>
                            Country
                                </label >
                    </div>
                    <div className="col-md-3">
                        <select id={this.props.id + 'Country'} class="form-control" value={this.props.country} onChange={this.props.onInputMethod}  >
                            {this.props.countries.map((item) => (<option key={item} value={item}>{item}</option>))}
                        </select>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
import React, { Component } from 'react';
import ar15 from '../asset/AR15.jpg';

import { Container } from 'reactstrap';
import './Donate.css';
import { Address } from './Address';

//const phoneRegEx = new RegExp( /^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-/\s.]?[0-9]{4}$/ );
const amountRegEx = new RegExp(/[0-9]+/);

export class Donate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            countries: [], amountEntered: '', donation: 0.0, email: '', phone: '',
            billingFirstName: '', billingLastName: '', billingAddress1: '', billingAddress2: '', billingCity: '', billingState: '', billingZip: '', billingCountry: 'United States',
            creditCardNumber: '', expirationDate: '', ccv: ''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getCountries();
    }

    handleSubmit = event => {
        this.contribute();
        event.preventDefault();
    };

    contribute(event) {

        alert(this.state.donation)
        return false;

    }

    async getCountries() {
        var baseURL = document.getElementsByTagName('base')[0].href;
        const response = await fetch(baseURL + 'country');
        var data = await response.json();
        this.setState({ countries: data, loading: false });
    }

    donationAmount(amount) {
        if (amount > -1) {
            if (this.state.donation > 0) {
                if (document.getElementById('donate' + this.state.donation) != null)
                    document.getElementById('donate' + this.state.donation).style.backgroundColor = "firebrick";
            }

            if (amount == 0) {
                document.getElementById('otherAmount').style.display = 'none';
                document.getElementById('enterAmount').style.display = 'block';
                document.getElementById('amountEntered').focus();
                this.state.donation = amountRegEx.exec(this.state.amountEntered);
            }
            else {
                if (document.getElementById('otherAmount').style.display != 'block') {
                    document.getElementById('otherAmount').style.display = 'block';
                    document.getElementById('enterAmount').style.display = 'none';
                }

                document.getElementById('donate' + amount).style.backgroundColor = "green";
                this.state.donation = amount;
            }
        }
    }

    handleInputChange(event) {
        {/*
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;
        */}

        switch (event.target.id) {
            case 'amountEntered':
                var newValue = amountRegEx.exec(event.target.value);
                this.state.donation = (newValue != null) ? newValue : 0;

                this.setState({
                    [event.target.id]: ((newValue != null) && (newValue != '0')) ? '$' + amountRegEx.exec(event.target.value) : ''
                });
                break;
            case 'creditCardNumber':
            case 'ccv':
            case 'expirationDate':
                this.setState({
                    [event.target.id]: (amountRegEx.exec(event.target.value) != null) ? amountRegEx.exec(event.target.value) : ''
                });
                break;
            default:
                var newValue = event.target.value;

                if ((newValue == null) || (newValue.trim() == ''))
                    newValue = '';

                this.setState({
                    [event.target.id]: newValue
                });
                break;
        }
    }


    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <br />
                <div className='container-fluid'>
                    <div className='row' >
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12" style={{ textAlign: "center", padding: '35px 0px 35px 0px' }}>
                                    <div className='titleBurst'>Donate to the National Weapons Association</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12" style={{ textAlign: 'center' }}>
                                    <h3 className='orgPurpose'>Proud Americans that want to own an assault weapon must join the military.</h3>
                                    <h4>Earn an automatic weapons license for life by completing your stretch in the USA Military with automatic weapons training.</h4>
                                    <p><h5>Secure weapon storage and other government restrictions are required.</h5></p>
                                    <div className='AR15 fadeIn'>
                                        <img src={ar15} alt='assault rifle' width='50%' />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                    <div className='componentPurpose'>I WOULD LIKE TO CONTRIBUTE THIS AMOUNT TO THE NWA</div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="offset-md-3 col-md-6">
                                    <table className='donateTable'>
                                        <thead></thead>
                                        <tbody>
                                            <tr>
                                                <td id="donate25" className='donateTD' onClick={() => this.donationAmount(25)} >$25</td>
                                                <td id="donate50" className='donateTD' onClick={() => this.donationAmount(50)} >$50</td>
                                                <td id="donate100" className='donateTD' onClick={() => this.donationAmount(100)} >$100</td>
                                                <td id="donate250" className='donateTD' onClick={() => this.donationAmount(250)} >$250</td>
                                                <td id="donate500" className='donateTD' onClick={() => this.donationAmount(500)} >$500</td>
                                                <td id="donate1000" className='donateTD' onClick={() => this.donationAmount(1000)} >$1000</td>
                                            </tr>
                                            <tr >
                                                <td id="donate0" colSpan='6'   >
                                                    <div className='container'>
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                <div id='otherAmount' className='donateOther' style={{ backgroundColor: 'firebrick' }} onClick={() => this.donationAmount(0)} > <div style={{ padding: '12px' }}>OTHER AMOUNT</div></div>
                                                                <div id='enterAmount' className='container donateOther' style={{ display: 'none' }}>
                                                                    <div className='row' >
                                                                        <div class=' offset-md-2 col-md-4' style={{ backgroundColor: 'green', padding: '6px 0px 6px 0px' , textAlign: 'center' }}>
                                                                            <label for='amountEntered' className='form-control-label'>
                                                                                Enter Amount
                                                                            </label >
                                                                        </div>
                                                                        <div class='col-md-4' style={{ backgroundColor: 'green', padding: '6px' }}>
                                                                             <input id='amountEntered' maxLength='9' class="form-control amountEnter" type="text" value={this.state.amountEntered} onChange={this.handleInputChange} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                            <div className="row">
                                <div className="offset-md-4 col-md-8">
                                    <br />
                                    <h4>Billing Address Information</h4>
                                    <br />
                                </div>
                            </div>
                            <div className="row">
                                <label for='email' className='form-control-label offset-md-4 col-md-1 required'>
                                    Email Address
                                    </label >
                                <div className="col-md-3">
                                    <input id='email' autofocus class="form-control" type="email" placeholder="example@domain.com" value={this.state.email} onChange={this.handleInputChange} required />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <label for='phone' className='form-control-label offset-md-4 col-md-1 required'>
                                    Phone Number
                                    </label>
                                <div className="col-md-3">
                                    <input id='phone' class="form-control form-control-warning" type="text" value={this.state.phone} onChange={this.handleInputChange} required />
                                </div>
                            </div>
                            <br />

                            <Address id='billing' onInputMethod={this.handleInputChange} firstName={this.state.billingFirstName} lastName={this.state.billingLastName} address1={this.state.billingAddress1} address2={this.state.billingAddress2} city={this.state.billingCity} state={this.state.billingState} zip={this.state.billingZip} country={this.state.billingCountry} countries={this.state.countries}></Address>

                            <div className="row">
                                <div className="offset-md-4 col-md-8">
                                    <br />
                                    <h4>Payment Information</h4>
                                    <br />
                                </div>
                            </div>
                            <div className="row">
                                <label for='creditCardNumber' className='form-control-label offset-md-4 col-md-1 required'>
                                    Credit Card Num
                                    </label>
                                <div className="col-md-3">
                                    <input id='creditCardNumber' maxLength='20' class="form-control" type="text" value={this.state.creditCardNumber} onChange={this.handleInputChange} required />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <label for='expirationDate' className='form-control-label offset-md-4 col-md-1 required'>
                                    Expiration Date
                                    </label>
                                <div className="col-md-3">
                                    <input id='expirationDate' class="form-control" type="text" maxLength='4' placeholder="MMYY" style={{ width: '80px' }} value={this.state.expirationDate} onChange={this.handleInputChange} required />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <label for='ccv' className='form-control-label offset-md-4 col-md-1 required'>
                                    Security Code
                                    </label>
                                <div className="col-md-3">
                                    <input id='ccv' class="form-control" type="text" maxLength='4' style={{ width: '80px' }} value={this.state.ccv} onChange={this.handleInputChange} required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                    <br />
                                    <br />
                                    <button type='submit' className="contributeButton" disabled='true'>Contribute Now</button>
                                    <br />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

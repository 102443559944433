import React, { Component } from 'react';
import { Membership } from './Membership'

const element = (
    <div>
        <div className='container-fluid' >
            <div className='row' >
                <div className="col-md-12" style={{ textAlign: "center", margin: '0px', padding: '30px 0px 35px 0px' }}>
                    <div className='titleBurst'>Join the National Weapons Association</div>
                </div>
            </div>
            <div className='row' >
                <div className="col-md-12" >
                    <Membership membershipType="Join" />
                </div>
            </div>
        </div>
    </div>
);

export class Join extends Component { 

    render() {
        return (
            element
        );
    }
}


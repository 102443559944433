import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink  } from 'reactstrap';
import { Link } from 'react-router-dom'; 
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header className='slideInFromTop'>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 navMenuStyle" light >
                    <Container style={{ maxWidth: '100%' }}>
                        <NavbarBrand tag={Link} to="/">
                            <table style={{ border: '3px solid black' }}>
                                <thead></thead>
                                <tbody>
                                    <tr>
                                        <td style={{ padding: '4px 10px 0px 10px', backgroundColor: 'darkred', color: 'white' }}>
                                        <h3><b>WeapAss</b></h3>
                                        </td>
                                        <td style={{ backgroundColor: 'white', width: '10px' }} ></td>
                                        <td style={{ padding: '4px 8px 0px 12px', backgroundColor: 'navy', color: 'whitesmoke' }}>
                                        <h5><b>Explore</b></h5>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot></tfoot>
                            </table>
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow" >
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/join">Join</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/renew">Renew</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/donate">Donate</NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}

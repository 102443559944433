import React, { Component } from 'react';
import { Membership } from './Membership'


export class Renew extends Membership {

    constructor(props) {
        super(props);
        this.state = {
            membershipNumber: ''
        };
    }

    render() {
        return (
            <div>
                <div className='container-fluid' >
                    <div className='row' >
                        <div className="col-md-12" style={{ textAlign: "center", padding: '35px 0px 35px 0px', margin: '0px' }}>
                            <div className='titleBurst'>Renew Membership to the National Weapons Association</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className='offset-md-4 col-md-1 noPadding' >
                            <label for='membershipNumber' className='form-control-label'>
                                Membership Number
                            </label >
                        </div>
                        <div className="col-md-3">
                            <input id='membershipNumber' autofocus class="form-control" type="text" value={this.state.membershipNumber} onChange={this.handleInputChange} placeholder="Not required to renew membership" maxLength='16' style={{ width: '320px' }} />
                        </div>
                    </div>
                    <div className='row' >
                        <div className="col-md-12">
                            <Membership membershipType="Renew" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

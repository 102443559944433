import React, { Component } from 'react';

export class Footer extends Component { 

    render() {
        return (
            <div style={{ backgroundImage: 'linear-gradient(55deg, rgba(255, 255, 255, 0.75), rgba(8, 4, 4, 1))' }} >
                <div style={{ height: '80px', textAlign: 'center', color: "white" }}>
                    <br />
                    <p>
                        Contributions, gifts or membership dues made or paid to the National Weapons Association of America are not refundable or transferable.
                    </p>
                </div>

                <div style={{ padding: '8px', height: '40px'}}>
                    <div style={{ float: 'right', fontSize: '11pt', marginRight: '30px', color: 'whitesmoke' }}>
                        <a href='https://www.SoftwareGladiator.com/' target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'underline' }}>website developed by <img src="CodeOfArmsLogo.png" style={{ height: '16px', margin: '0px 5px 0px 5px' }} alt='code of arms' />Software Gladiator</a>
                    </div>
                </div>
            </div>
        );
    }
}
